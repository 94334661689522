import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          )
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify', element: <VerifyCode /> }
      ]
    },

    // Dashboard Routes
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: '/', element: <Dashboard /> },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/profile" replace /> },
            { path: 'profile', element: <UserProfile /> },
            { path: 'cards', element: <UserCards /> },
            { path: 'list', element: <UserList /> },
            { path: 'new', element: <UserCreate /> },
            { path: ':name/edit', element: <UserCreate /> },
            { path: 'account', element: <UserAccount /> }
          ]
        },
        {
          path: 'product',
          children: [
            { element: <Navigate to="/product" replace /> },
            { path: 'list', element: <ProductList /> },
            { path: 'create', element: <ProductCreate /> },
            { path: 'edit/:slug', element: <ProductCreate /> },
            { path: 'view/:slug', element: <ProductDetails /> }
          ]
        },
        {
          path: 'category',
          children: [{ element: <Navigate to="/category" replace /> }, { path: 'list', element: <CategoryList /> }]
        },
        {
          path: 'customer',
          children: [{ element: <Navigate to="/customer" replace /> }, { path: 'list', element: <CustomerList /> }]
        },
        {
          path: 'reports',
          children: [{ element: <Navigate to="/reports" replace /> }, { path: 'list', element: <Reports /> }]
        },
        {
          path: 'expenses',
          children: [{ element: <Navigate to="/expenses" replace /> }, { path: 'list', element: <Expense /> }]
        },
        {
          path: 'orders',
          children: [
            { element: <Navigate to="/orders" replace /> },
            { path: 'latest', element: <Order /> },
            { path: 'list', element: <OrderList /> },
            { path: 'view/:orderId', element: <OrderDetails /> },
            { path: 'placeorder', element: <PlaceOrder /> },
            { path: 'customorder', element: <CustomOrder /> },
          ]
        }
      ]
    }
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const Register = Loadable(lazy(() => import('../pages/authentication/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/authentication/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/authentication/VerifyCode')));

// Dashboard
const Dashboard = Loadable(lazy(() => import('../pages/dashboard/index')));

// Users

const UserProfile = Loadable(lazy(() => import('../pages/dashboard/user/UserProfile')));
const UserCards = Loadable(lazy(() => import('../pages/dashboard/user/UserCards')));
const UserList = Loadable(lazy(() => import('../pages/dashboard/user/UserList')));
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/user/UserAccount')));
const UserCreate = Loadable(lazy(() => import('../pages/dashboard/user/UserCreate')));

// Category
const CategoryList = Loadable(lazy(() => import('../pages/dashboard/category/CategoryList')));

// Products
const ProductList = Loadable(lazy(() => import('../pages/dashboard/product/ProductList')));
const ProductCreate = Loadable(lazy(() => import('../pages/dashboard/product/ProductCreate')));
const ProductDetails = Loadable(lazy(() => import('../pages/dashboard/product/ProductDetails')));

// Customer
const CustomerList = Loadable(lazy(() => import('../pages/dashboard/customer/CustomerList')));

// Order
const Order = Loadable(lazy(() => import('../pages/dashboard/order/Order')));
const OrderList = Loadable(lazy(() => import('../pages/dashboard/order/OrderList')));
const OrderDetails = Loadable(lazy(() => import('../pages/dashboard/order/OrderDetails')));
const PlaceOrder = Loadable(lazy(() => import('../pages/dashboard/order/PlaceOrder')));
const CustomOrder = Loadable(lazy(() => import('../pages/dashboard/order/CustomOrder')));

// Reports
const Reports = Loadable(lazy(() => import('../pages/dashboard/report/GeneralAnalytics')));
const Expense = Loadable(lazy(() => import('../pages/dashboard/expense/ExpenseList')));