// hooks
// import useAuth from '../hooks/useAuth';
//
import { MAvatar } from './@material-extend';
import createAvatar from '../utils/createAvatar';

// ----------------------------------------------------------------------

export default function MyAvatar(/* { ...other } */) {
  return (
    <MAvatar src="" alt="ADMIN" color={createAvatar('ADMIN').color}>
      {createAvatar('ADMIN').name}
    </MAvatar>
  );
}
